@use 'variable';
@use 'mixins' as *;

.project {
  margin: 4rem 0 0;

  &__introduction, &__container, &__content {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
  }

  &__introduction {
    height: 100vh;
    display: flex;

    .project__content {
      display: block;
      margin: auto;

      @include respond-to('desktop') {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .project__content-title {
        text-align: center;
        grid-column: none;
        width: auto;
        color: variable.$blue;
        display: block;
        margin: 0 0 4rem;
      }

      .project__content-description {
        text-align: center;
        grid-column: none;
        width: auto;
        height: auto;
        margin: auto;
      }

      @include respond-to('desktop') {

        .project__content-title {
          grid-column: none;
        }

        .project__content-description {
          grid-column: none;
          width: 70%;
        }
      }
    }
  }

  &__container {
    z-index: 2;

    .custom-cursor {
      z-index: 9999;
      position: absolute;
      pointer-events: none;
      border-radius: 100%;
      background: variable.$white;
      color: white;
      text-align: center;
      line-height: 40px;
      width: 200px;
      height: 200px;
      transform: translate(-50%, -50%) scale(0);
      transition: transform .3s;

      &.--active {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    .project__items {
      @include respond-to('desktop') {
        display: flex;
        width: calc(100vw * 2);
      }
    }
  }

  &__content {
    position: relative;
    padding: variable.$base-padding-y variable.$base-padding-x;
    display: grid;
    grid-template-columns: repeat(12,minmax(0,1fr));
    column-gap: variable.$grid-gap;
    grid-template-rows: auto 1fr auto;
    margin: 0 0 6rem;

    @include respond-to('desktop') {
      height: 100vh;
      flex-shrink: 0;
      padding: 6rem;
      width: 100vw;
    }

    &-background {
      position: absolute;
      max-width: calc(100vw + 6vw);
      margin: 0;
      font-size: calc(1rem + 35vw);
      color: #E5ECFB;
      white-space: nowrap;
      text-transform: uppercase;
      font-weight: 700;
      user-select: none;
      top: 50%;
      left: 50%;
      transform: translate(-52%, -35%);

      @include respond-to('desktop') {
        transform: translate(-50%, -50%);
      }
    }

    &-title, &-description, &-list {
      z-index: 2;
      grid-column: 1 / span 12;
      font-size: 1.4rem;
      color: variable.$black;
    }

    &-title {
      font-size: clamp(2.7rem, 4vw + 1rem, 5.6rem);
      font-weight: 700;
      font-family: variable.$lato;
      text-transform: uppercase;
      position: relative;
      text-align: left;
      margin: 0;
      color: variable.$white;
      display: initial;
      transform: translate(0, 1rem);

      &:last-child {
        color: variable.$blue;
        display: block;
        margin: 0 auto 5rem;
        transform: translate(-3rem, 0);

        span {
          transform: translate(2rem, 2.5rem);

          &:last-child {
            transform: translate(7.5rem, 5rem);
          }
        }
      }

      @include respond-to('desktop') {
        text-align: left;
      }
    }

    &-description {
      line-height: 1.5;
    }

    &-list {
      font-size: 1rem;
      color: variable.$black;
      padding: 0;
      margin: 0;

      @include respond-to('desktop') {
        grid-column: 3 / span 8;
      }

      li {
        list-style: none;
        display: inline-block;
      }
    }

    .project__image-wrapper {
      grid-column: 1 / span 12;
      z-index: 2;
      position: relative;
      padding: 8rem .5rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include respond-to('desktop') {
        padding: 0;
        width: 60%;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: .375rem;
        //position: relative;
        cursor: pointer;
        //display: block;
      }
    }
  }
}