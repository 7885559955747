@use 'variable';
@use 'mixins' as *;

.skill {
  padding: variable.$base-padding-y variable.$base-padding-x;
  display: grid;
  grid-template-columns: repeat(12,minmax(0,1fr));
  column-gap: variable.$grid-gap;
  grid-template-rows: auto 1fr auto;
  margin: 8rem 0 0;

  .skill__title {
    z-index: 2;
    grid-column: 1 / span 12;
    font-size: clamp(2.7rem, 4vw + 1rem, 5.6rem);
    font-weight: 700;
    font-family: variable.$lato;
    text-transform: uppercase;
    margin: 4rem 0;
    text-align: left;
  }

  .skill__description {
    z-index: 2;
    grid-column: 1 / span 12;
    line-height : 1.5;
    font-size: clamp(1rem, 1.5vw, 1.5rem);

    @include respond-to('tablet') {
      grid-column: span 4 / span 6;
    }

    @include respond-to('desktop') {
      grid-column: span 6 / span 6;
    }
  }

  .skill__content {
    z-index: 2;
    grid-column: 1 / span 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem 0 0;

    @include respond-to('tablet') {
      flex-direction: row;
      align-items: stretch;
      grid-column: span 8 / span 8;
      margin: 0;
    }

    @include respond-to('desktop') {
      grid-column: span 6 / span 6;
    }

    .skill__content-container {
      flex: 1;
      display: flex;
      margin: 0 0 1rem;
      width: 14rem;
      perspective: 100rem;

      &:last-child {
        margin: 0;
      }

      @include respond-to('tablet') {
        margin: 0 1rem 0 0;

        &:last-child {
          margin: 0;
        }
      }

      .skill__content-card {
        width: 100%;
        padding: 1.5rem;
        border: 2px solid variable.$blue;
        border-radius: 0.375rem;
        transition: transform 0.1s linear, background-color .3s;
        transform-origin: center;

        .skill__content-icon {
          width: 3rem;
          height: 3rem;
          margin: 0 0 9rem;

          path {
            fill: variable.$blue;
            transition: fill .3s;
          }
        }

        .skill__content-title {
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 700;
          color: variable.$black;
          transition: color .3s;
        }
      }

      &:hover {

        .skill__content-card {
          background-color: variable.$blue;

          .skill__content-icon {

            path {
              fill: variable.$white;
            }
          }

          .skill__content-title {
            color: variable.$white;
          }
        }
      }
    }
  }
}