@use 'variable';
@use 'mixins' as *;

.header {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  min-height: 35rem;

  &.--off {
    height: 8rem;
    min-height: 0;
  }

  @include respond-to('phablet') {
    min-height: 40rem;
  }

  .header__hero-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); // Centre le SVG
    width: 160%;
    height: auto; // Assure que le SVG prend toute la hauteur de la section

    @include respond-to('phablet') {
      width: 120%;
    }

    @include respond-to('tablet-landscape') {
      width: 110%;
    }
  }

  @keyframes logoEnter {
    from { transform: translateX(-100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }

  @keyframes buttonEnter {
    from { transform: translateX(100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }

  .header__navigation {
    z-index: 4;
    display: flex;
    position: fixed;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    padding: variable.$base-padding-y variable.$base-padding-x;

    .header__navigation-logo {
      all: unset;
      cursor: pointer;

      &.--hidden {
        opacity: 0;
        pointer-events: none;
      }

      &.--active {
        animation: logoEnter 0.5s forwards;
      }

      svg {
        width: 8rem;
      }
    }

    .header__navigation-action {
      margin: 0;
      display: flex;
      align-items: center;

      .header__navigation-button {
        all: unset;
        display: inline-block;
        overflow: hidden;
        position: relative;

        &.--circle {
          border-radius: 1.6rem;
        }

        a {

          &.--hidden {
            opacity: 0;
            pointer-events: none;
          }

          &.--active {
            animation: buttonEnter 0.5s forwards;
            background-color: variable.$blue;
            color: variable.$white;
          }

          .icon {
            width: 1.2rem;
          }
        }

        &:first-child {
          display: none;

          &.--hidden {
            display: none;
          }

          @include respond-to('tablet') {
            display: inline-block;
          }

          a {
            margin: 0 2rem 0 0;
          }
        }

        .su_button_circle {
          border-radius: 1000px;
          position: absolute;
          left:0;
          top:0;
          width: 0;
          height: 0;
          margin-left: 0;
          margin-top: 0;
          pointer-events: none;
          z-index: 5;
        }

        .--previewBtn {
          transition: color .3s, background-color .5s;

          &:hover, &:focus {
            color: variable.$white;
            background-color: variable.$blue;
          }

          span {
            z-index: 6;
          }
        }

        .explode-circle {
          animation: explode .3s forwards;
        }

        .desplode-circle {
          animation: desplode .5s forwards;
        }

        @keyframes explode {
          0% {
            width: 0;
            height: 0;
            margin-left: 0;
            margin-top: 0;
            background-color: variable.$blue;
          }
          100% {
            width: 400px;
            height: 400px;
            margin-left: -200px;
            margin-top: -200px;
            background-color: variable.$blue;
          }
        }

        @keyframes desplode {
          0% {
            width: 400px;
            height: 400px;
            margin-left: -200px;
            margin-top: -200px;
            background-color: variable.$blue;
          }
          100% {
            width: 0;
            height: 0;
            margin-left: 0;
            margin-top: 0;
            background-color: variable.$blue;
          }
        }
      }
    }
  }

  .header__menu {
    z-index: 3;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: calc(var(--menu, 1vh) * 100);
    background-color: variable.$grey;
    padding: variable.$base-padding-y variable.$base-padding-x;
    top: -150%;
    transition: top 0.5s;

    &.--hidden {
      top: -150%;
    }

    &.--shown {
      top: 0;
    }

    .header__menu-action, .header__menu-social {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin: 0 0 1rem;
        align-items: center;
        display: flex;

        &:last-child {
          margin: 0;
        }

        .header__menu-item {
          text-decoration: none;
          color: variable.$black;
          overflow: hidden;
          height: 2rem;

          span {
            width: fit-content;
            display: flex;
            transition: transform 0.3s ease, opacity 0.3s ease;
            transform: translateY(0%);
          }

          .original {
            opacity: 1;
          }

          .duplicate {
            transform: translateY(100%);
            opacity: 0;
          }

          &:hover {
            .original {
              transform: translateY(-100%);
              opacity: 0;
            }

            .duplicate {
              transform: translateY(-100%);
              opacity: 1;
            }
          }
        }
      }
    }

    .header__menu-action {
      padding: 7rem 0 0;

      li {
        font-size: 1.875rem;
        text-transform: uppercase;
      }
    }

    .header__menu-social {

      li {

        a {
          font-size: 1.4rem;
          text-transform: initial;
          color: variable.$black;

          .icon {
            margin: 0 0 0 .4rem;
            width: 1.4rem;
          }
        }
      }

    }
  }

  .header__hero {
    //margin: 20vh 0 0;
    margin: auto 0;

    .header__hero-container {
      padding: variable.$base-padding-y variable.$base-padding-x;
      display: grid;
      grid-template-columns: repeat(12,minmax(0,1fr));
      column-gap: variable.$grid-gap;
      grid-template-rows: auto 1fr auto;
      margin: 4rem 0 0;

      .header__hero-title {
        z-index: 2;
        font-size: 12vw;
        font-weight: 700;
        font-family: variable.$lato;
        line-height : 1;
        margin: 0 0 2.5rem;
        grid-column: 1 / span 12;
        text-transform: uppercase;
        color: variable.$blue;

        @include respond-to('phablet') {
          grid-column: 1 / span 6;
          font-size: 8vw;
        }

        @include respond-to('tablet-landscape') {
          font-size: 6.5vw;
        }

        @include respond-to('desktop') {
          //font-size: 4.5vw;
          font-size: 6.5vw;
          grid-column: 1 / span 8;
        }
      }

      .header__hero-description {
        z-index: 2;
        grid-column: 1 / span 12;
        grid-row: 2;
        //font-size: clamp(1.4rem, 1.5vw, 2.4rem);
        font-size: clamp(1rem, 1.5vw, 1.5rem);
        line-height : 1.5;

        @include respond-to('tablet') {
          grid-column: 1 / span 8;
        }

        @include respond-to('desktop') {
          grid-column: 1 / span 5;
        }
      }

      .header__hero-scene {
        all: unset;
        grid-column: 1 / span 12;
        display: none;

        @include respond-to('tablet-landscape') {
          display: initial;
        }


        @include respond-to('tablet-landscape') {
          grid-column: 9 / span 4;
          grid-row: 1 / span 2;
        }

        .header__hero-scene-content {
          margin: 12rem 0 0;
          z-index: 2;
          width: 100%;
          height: 40vh;
          position: relative;
        }
      }
    }
  }
}