@use 'variable';
@use 'mixins' as *;

.footer {
  margin: 6rem 0 8rem;
  padding: 5rem 0 0;
  position: relative;
  overflow: hidden;

  @include respond-to('tablet-landscape') {
    padding: 10rem 0 0;
  }

  @include respond-to('desktop') {
    padding: 14rem 0 0;
  }

  .footer__background {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0); // Centre le SVG
    width: 160%;
    height: auto; // Assure que le SVG prend toute la hauteur de la section

    @include respond-to('phablet') {
      width: 120%;
    }

    @include respond-to('tablet-landscape') {
      width: 110%;
    }
  }

  .footer__link {
    padding: variable.$base-padding-y variable.$base-padding-x;
    margin: 0 0 2rem;

    @include respond-to('tablet') {
      display: flex;
      justify-content: space-between;
      //flex-direction: column;
    }

    .footer__link-social {
      z-index: 2;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      list-style: none;
      margin: 0 0 2rem;

      @include respond-to('tablet') {
        margin: 0;
      }

      .footer__link-item {
        font-size: 1.4rem;
        list-style: none;
        margin: 0 0 1rem;
        align-items: center;
        display: flex;
        color: variable.$black;
        text-transform: initial;

        .icon {
          width: 1.4rem;
          margin: 0 0 0 .4rem;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    .footer__link-other {
      z-index: 2;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .footer__other-button {
        margin: 0 0 2rem;
        color: variable.$black;
        font-size: 1.4rem;
      }

      .footer__link-legal {

        .footer__link-item {
          color: variable.$black;
          text-decoration: none;
          font-size: 1rem;
          display: block;
          margin: 0 0 1rem;

          &:last-child {
            margin: 0;
          }

          @include respond-to('tablet') {
            display: inline-block;
            margin: 0 2rem 0 0;

            &:last-child {
              margin: 0;
            }
          }

        }
      }
    }
  }

  .footer__copyright {
    z-index: 2;
    padding: variable.$base-padding-y variable.$base-padding-x;
    text-align: center;
    color: variable.$black;
    margin: 2rem 0 0;
    font-size: 1rem;

    @include respond-to('tablet') {
      text-align: left;
    }
  }
}