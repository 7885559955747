$breakpoints: (
        "phone": 480px,
        "phablet": 576px,
        "tablet": 768px,
        "tablet-landscape": 992px,
        "desktop": 1200px,
        "large-desktop": 1440px,
);

@mixin respond-to($name) {
  @if map-has-key($breakpoints, $name) {
    $width: map-get($breakpoints, $name);

    @media (min-width: $width) {
      @content;
    }
  } @else {
    @warn "Breakpoint '#{$name}' is not define";
  }
}