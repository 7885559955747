@use 'variable';
@use 'mixins' as *;

.legal {
  padding: variable.$base-padding-y variable.$base-padding-x;
  //margin: 8rem 0 0;
  display: grid;
  grid-template-columns: repeat(12,minmax(0,1fr));
  column-gap: variable.$grid-gap;
  grid-template-rows: auto 1fr auto;

  .legal__title {
    z-index: 2;
    font-size: clamp(2.7rem, 4vw + 1rem, 5.6rem);
    line-height: clamp(2.5rem, 4vw + 1rem, 5.4rem);
    margin: 0 0 4rem;
    font-weight: 700;
    font-family: variable.$lato;
    grid-column: 1 / span 12;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
  }

  .legal__content {
    grid-column: 1 / span 12;

    .legal__content-title {
      font-weight: 700;
      font-size: clamp(1rem, 1.5vw, 1.5rem);
      margin: 3rem 0 1.5rem;

      &:first-child {
        margin: 0 0 1.5rem;
      }
    }

    .legal__content-list {
      padding: 0;
      margin: 0;

      .legal__content-item {
        list-style: none;
        font-size: clamp(1rem, 1.5vw, 1.5rem);
        margin: 0 0 1rem;
      }
    }

    .legal__content-text {
      font-size: clamp(1rem, 1.5vw, 1.5rem);
      line-height: 1.5;
    }
  }
}