@use 'variable';
@use 'mixins' as *;

.about {
  padding: variable.$base-padding-y variable.$base-padding-x;
  display: grid;
  grid-template-columns: repeat(12,minmax(0,1fr));
  column-gap: variable.$grid-gap;
  grid-template-rows: auto 1fr auto;
  margin: 4rem 0 0;
  position: relative;
  overflow: hidden;
  min-height: 20rem;

  @include respond-to('tablet-landscape') {
    min-height: 33rem;
  }

  @include respond-to('desktop') {
    margin: 12rem 0 0;
  }

  .about__background {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0); // Centre le SVG
    width: 160%;
    height: auto; // Assure que le SVG prend toute la hauteur de la section

    @include respond-to('phablet') {
      width: 120%;
    }

    @include respond-to('tablet-landscape') {
      width: 110%;
    }

    //@include respond-to('tablet-landscape') {
    //  top: 6rem;
    //  width: 120%;
    //}
  }

  .about__title {
    z-index: 2;
    font-size: clamp(2.7rem, 4vw + 1rem, 5.6rem);
    line-height: clamp(2.5rem, 4vw + 1rem, 5.4rem);
    margin: 4rem 0;
    font-weight: 700;
    font-family: variable.$lato; // Assurez-vous que cette variable est correctement définie
    grid-column: 1 / span 12;
    text-transform: uppercase;
    position: relative;
    overflow: hidden; // Masque le contenu débordant pendant l'animation

    // La section commentée sur le positionnement absolu des spans est retirée
    // car elle pourrait interférer avec l'effet de révélation souhaité

    @include respond-to('tablet') {
      // Ajustement pour les appareils tablettes
      span {
        left: clamp(12rem, 20vw + 1rem, 30rem);
      }
    }
  }

  .about__description {
    z-index: 2;
    grid-column: 1 / span 12;
    line-height : 1.5;
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    margin: 0 0 2.5rem;

    @include respond-to('tablet') {
      grid-column: 7 / span 6;
    }
  }

  .about__button {
    z-index: 2;
    grid-column: 1 / span 12;
    font-size: 1.4rem;

    @include respond-to('tablet') {
      grid-column: 1 / span 12;
    }
  }
}