@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

$lato: 'Lato';
$roboto: 'Roboto';

$white: #F0F2F5;
$black: #1D1D1B;
$grey: #DEE4F0;
$blue: #4083FF;
$blueds: #285fd7;
$orange: #FF880A;
$purple: #7E41FF;

$base-padding-x: max(10vw, 1.5rem);
$base-padding-y: clamp(1.5rem, 4vw, 3.2rem);
$grid-gap: 2vw;
