@use 'variable';
@use 'mixins' as *;

.contact {
  padding: variable.$base-padding-y variable.$base-padding-x;
  display: grid;
  grid-template-columns: repeat(12,minmax(0,1fr));
  column-gap: variable.$grid-gap;
  grid-template-rows: auto 1fr auto;
  position: relative;
  //background-color: variable.$black;
  margin: 8rem 0 0;

  .contact__title {
    z-index: 1;
    grid-column: 1 / span 12;
    font-size: clamp(2.7rem, 4vw + 1rem, 5.6rem);
    //line-height : clamp(2.5rem, 4vw + 1rem, 5.4rem);
    //margin: 0 auto clamp(7.5rem, 10vw + 2rem, 30rem);
    margin: 4rem 0;
    font-weight: 700;
    font-family: variable.$lato;
    text-transform: uppercase;
    position: relative;
    //color: variable.$blue;
    color: variable.$black;
    display: block;
    //transform: translate(-3rem, 0);
    text-align: left;

    @include respond-to('desktop') {
      //transform: translate(-5rem, 0);
    }
  }

  .contact__description {
    z-index: 2;
    grid-column: 1 / span 12;
    line-height : 1.5;
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    margin: 0 0 2.5rem;
  }

  .input-error {
    border: 2px solid #cb1616 !important;
  }

  .status-messages {
    color: #cb1616;
    margin-bottom: .8rem;
    font-size: .8rem;
    visibility: hidden;
    grid-column: 1 / span 12;

    @include respond-to('desktop') {
      grid-column: 4 / span 6;
    }
  }

  .contact__form {
    width: 100%;
    grid-column: 1 / span 12;
    margin: 0 0 4rem;

    @include respond-to('desktop') {
      grid-column: 4 / span 6;
    }

    span {
      color: variable.$black;
    }

    label {
      display: block;

      input, textarea {
        all: unset;
        box-sizing: border-box;
        display: block;
        width: 100%;
        background-color: #E9EDF1;
        padding: .25rem .75rem;
        margin: .5rem 0 2rem;
        border-radius: 0.625rem;
        min-height: 3rem;
        font-size: 1rem;
        color: variable.$black;
        border: 2px solid #DEE2E7;
        transition: border .3s;

        &::placeholder {
          color: #9EA2AA;
          opacity: 1;
        }

        &:hover {
          cursor: text;
        }

        &:focus {
          border: 2px solid variable.$blue;
        }
      }

      textarea {
        padding: 1rem .75rem;
        min-height: 10rem;
        resize: vertical;
      }
    }
  }

  .contact__background {
    z-index: -1;
    position: absolute;
    left: -6vw;
    bottom: 0;
    max-width: calc(100vw + 6vw);
    overflow-x: hidden;
    transform: translate(0, 6rem);
    margin: 0;
    font-size: calc(1rem + 35vw);
    color: variable.$black;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 700;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}