@use 'variable';

.button {
  all: unset;
  background-color: variable.$grey;
  color: variable.$black;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  padding: .8rem 1.8rem;
  border-radius: 1.6rem;
  align-items: center;
  display: flex;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: fit-content;
  transition: background-color .3s, color .3s;

  &.--form {
    position: relative;
    transition: transform .2s;

    &:hover {
      transform: scale(1.1); /* Agrandit le bouton au survol */
    }

    .content {
      align-items: center;
      display: flex;
      transition: transform .05s;
      color: variable.$white;
    }
  }

  &.--transparent {
    background-color: transparent;
    padding: 0;
    color: variable.$blue;
    cursor: pointer;
    border-radius: 0;
  }

  .button-text {
    transform: translateX(-11.5%);
    transition: transform .3s ease;
  }

  &.--blue {
    background-color: variable.$blue;
    color: variable.$white;
  }

  .icon {
    margin: 0 0 0 .4rem;
    width: 1.4rem;
    display: inline-block;
    transition: transform .3s ease, opacity .3s ease;
  }

  .left-icon {
    margin: 0;
    transform: translateX(-100%);
    opacity: 0;
  }

  .right-icon {
    margin: 0;
    transform: translateX(-50%);
    opacity: 1;
  }

  &:hover {
    .button-text {
      transform: translateX(.5rem); // Ajustez en fonction de l'espace nécessaire pour la flèche
    }

    .right-icon {
      transform: translateX(100%);
      opacity: 0;
    }

    .left-icon {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.--link-ext {

  span {
    align-items: center;
    display: flex;
    overflow: hidden;
  }

  .icon {
    transform: translateX(-120%) rotate(0);
    transition: transform .3s ease;
  }

  &:hover .icon {
    transform: translateX(0) rotate(-45deg); // Déplacer à droite et tourner de -45 degrés
  }
}

.--link {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom left;
    width: 100%;
    height: 1px;
    background-color: variable.$black;
    transition: transform .3s ease;
  }

  &:hover::after, &:focus::after {
    transform: scaleX(1);
  }
}