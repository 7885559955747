@use 'variable';
@use 'button';
@use 'header';
@use 'about';
@use 'skill';
@use 'project';
@use 'contact';
@use 'footer';
@use 'legalNotice';
@use 'mixins' as *;

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-family: variable.$roboto, sans-serif;
  background-color: variable.$white;
  color: variable.$black;
}

h1, p {
  margin: 0;
}

@media (min-width: 768px) {
  body {
    padding: 0;
    font-size: .8vw;
  }
}

@keyframes drawLine {
  to {
    stroke-dashoffset: 0;
  }
}

.--no-scroll {
  overflow: hidden;
  height: 100%;
}

.--social {
  opacity: 0;
  transform: translateX(30px);
}

.animate-in {
  opacity: 0;
  transform: translateX(30px); /* Ajuster selon le besoin */
  animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.carousel {
  z-index: 2;
  overflow: hidden;
  width: 100%;
  height: 1.8rem;
  margin: 0 0 1rem;
  //position: fixed;

  @include respond-to('desktop') {
    height: 2.2rem;
  }

  .carousel__logos {
    display: flex;
    position: relative;

    .carousel__logo {
      height: 1.8rem;
      flex-shrink: 0;
      margin: 0 1rem 0 0;

      @include respond-to('desktop') {
        height: 2.2rem;
      }
    }
  }
}
//
//@keyframes pulseAnimation {
//  0% {
//    transform: scale(1);
//    opacity: 1;
//  }
//  50% {
//    transform: scale(1.2);
//    opacity: 0.7;
//  }
//  100% {
//    transform: scale(1);
//    opacity: 1;
//  }
//}
//
//.wave {
//  fill: #0000FF; /* Couleur de la vague */
//  animation: pulseAnimation 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
//}

.project__background-parallax {
  grid-column: 1 / span 12;

  @include respond-to('desktop') {
    grid-column: 3 / span 8;
  }
  width: 100%; /* Ajustez à la taille désirée */
  height: 100%; /* Ajustez à la taille désirée */
  overflow: hidden;
  position: relative;
  border-radius: .625rem;
  margin: 0 auto; /* Centrer dans la page */

  .background {
    background-image: url('../img/background.png');
    background-size: cover;
    background-position: center center;
    position: absolute;
    width: 100%;
    height: 120vh; /* Plus grande pour permettre l'effet parallaxe */
    bottom: 0;  /* Ajustement pour couvrir la div parent */
    display: flex;
    justify-content: center;
    align-items: center;

    &.--1 {
      background-image: url('../img/bg/1.png');
    }

    &.--2 {
      background-image: url('../img/bg/2.png');
    }

    //&.--3 {
    //  background-image: url('../img/bg/1.png');
    //}
    //
    //&.--4 {
    //  background-image: url('../img/bg/2.webp');
    //}
    //
    //&.--5 {
    //  background-image: url('../img/bg/3.webp');
    //}
    //
    //&.--6 {
    //  background-image: url('../img/bg/4.webp');
    //}
    //
    //&.--7 {
    //  background-image: url('../img/bg/5.webp');
    //}
  }
}

.changeBackground, changeText, changeBackgroundText {
  transition: background-color .3s, color .3s;
}

.--dark__background {
  background-color: variable.$black; /* Changez la couleur selon vos besoins */
}

.--dark__text {
  color: variable.$white !important;
}

.--dark__background-text {
  color: #222220 !important;
}



.title-part {
  display: inline-block; // Permet l'animation individuelle
  overflow: hidden; // Important pour masquer les parties du texte avant leur animation

  span {
    display: inline-block; // Nécessaire pour l'animation de chaque lettre
    opacity: 0; // Initialement masqué
  }
}

// Aucun besoin de `transform: translateY(100%);` ici car GSAP s'en charge

.title-part.top {
  display: block;
}

// Aucun besoin de `transform: translateY(100%);` ici car GSAP s'en charge

.title-part.bottom {
  display: block;

  span {
    will-change: transform;
  }
}

.animated-text {

  span {
    opacity: 0.3;
    display: inline-block;
    transform: translateY(5px);
    transition: opacity .3s, transform .3s;
  }
}

.backgroundAnimation {
  color: #FF880A;
}